/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popup-confirmation-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/material/dialog";
import * as i8 from "@angular/common";
import * as i9 from "./popup-confirmation-dialog.component";
var styles_PopupConfirmationDialogComponent = [i0.styles];
var RenderType_PopupConfirmationDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopupConfirmationDialogComponent, data: {} });
export { RenderType_PopupConfirmationDialogComponent as RenderType_PopupConfirmationDialogComponent };
function View_PopupConfirmationDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["mat-dialog-close", ""], ["mat-stroked-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).dialogRef.close(i1.ɵnov(_v, 2).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onAbortClick() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(2, 606208, null, 0, i7.MatDialogClose, [[2, i7.MatDialogRef], i1.ElementRef, i7.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(3, 0, ["", ""]))], function (_ck, _v) { var currVal_3 = ""; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); var currVal_2 = (i1.ɵnov(_v, 2).ariaLabel || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _co.data.abort; _ck(_v, 3, 0, currVal_4); }); }
function View_PopupConfirmationDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["mat-dialog-close", ""], ["mat-stroked-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).dialogRef.close(i1.ɵnov(_v, 2).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onAbortClick() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(2, 606208, null, 0, i7.MatDialogClose, [[2, i7.MatDialogRef], i1.ElementRef, i7.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Abbrechen"]))], function (_ck, _v) { var currVal_3 = ""; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); var currVal_2 = (i1.ɵnov(_v, 2).ariaLabel || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_PopupConfirmationDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "mat-elevation-z0"], ["color", "primary"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["OK"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_PopupConfirmationDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "hd--shadowed s-padding-small mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i7.MatDialogTitle, [[2, i7.MatDialogRef], i1.ElementRef, i7.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i7.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "mat-typography"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 10, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i7.MatDialogActions, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupConfirmationDialogComponent_1)), i1.ɵdid(10, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupConfirmationDialogComponent_2)), i1.ɵdid(12, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "mat-elevation-z0"], ["color", "primary"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onConfirmClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(14, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(15, 0, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupConfirmationDialogComponent_3)), i1.ɵdid(17, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_3 = _co.data.abort; _ck(_v, 10, 0, currVal_3); var currVal_4 = !_co.data.abort; _ck(_v, 12, 0, currVal_4); var currVal_7 = "primary"; _ck(_v, 14, 0, currVal_7); var currVal_9 = !_co.data.confirm; _ck(_v, 17, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.data.body; _ck(_v, 6, 0, currVal_2); var currVal_5 = (i1.ɵnov(_v, 14).disabled || null); var currVal_6 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 13, 0, currVal_5, currVal_6); var currVal_8 = _co.data.confirm; _ck(_v, 15, 0, currVal_8); }); }
export function View_PopupConfirmationDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirmation-pop-up", [], null, null, null, View_PopupConfirmationDialogComponent_0, RenderType_PopupConfirmationDialogComponent)), i1.ɵdid(1, 49152, null, 0, i9.PopupConfirmationDialogComponent, [i7.MatDialogRef, i7.MAT_DIALOG_DATA], null, null)], null, null); }
var PopupConfirmationDialogComponentNgFactory = i1.ɵccf("app-confirmation-pop-up", i9.PopupConfirmationDialogComponent, View_PopupConfirmationDialogComponent_Host_0, {}, {}, []);
export { PopupConfirmationDialogComponentNgFactory as PopupConfirmationDialogComponentNgFactory };
