<h2 mat-dialog-title class="hd--shadowed s-padding-small">{{data.title}}</h2>
<mat-dialog-content>
    <p class="mat-typography">{{data.body}}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button (click)="onAbortClick()" *ngIf="data.abort" mat-dialog-close>{{data.abort}}</button>
    <button mat-stroked-button (click)="onAbortClick()" *ngIf="!data.abort" mat-dialog-close>Abbrechen</button>
    <button mat-raised-button color="primary" class="mat-elevation-z0" (click)="onConfirmClick()" >{{data.confirm}}</button>
    <button mat-raised-button color="primary" class="mat-elevation-z0" *ngIf="!data.confirm" >OK</button>
</mat-dialog-actions>
